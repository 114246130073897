import React from 'react'
import { CampaignCard, Text } from '@/components/common'
import { DavidCampaignCard } from '@/components/common/CampaignCard'
import { Campaign } from '@/services/api/campaignV2/campaign'
import classNames from 'classnames'
import ABTester, { ABTestProps } from '@/components/ABTester'

interface Props {
  campaigns: Campaign[]
  cardLayout?: 'single' | 'multiple'
  title: string
  subtitle?: string
}

export const CampaignSection: React.FC<Props> = ({
  campaigns,
  cardLayout = 'multiple',
  title,
  subtitle,
  ...rest
}) => {
  return (
    <div className="max-w-[1168px] mb-8 px-4 w-full" {...rest}>
      <Text
        as="h3"
        preset="heading.lg md:heading.xl"
        className="md:inline-block md:mr-2"
      >
        {title}
      </Text>
      {subtitle && (
        <Text
          as="p"
          preset="heading.sm md:heading.lg"
          className="font-normal text-gray-3 md:inline-block"
        >
          {subtitle}
        </Text>
      )}
      <div
        className={classNames('grid gap-6 mt-6', {
          'sm:grid-cols-2': cardLayout === 'multiple' && campaigns.length >= 2,
          'lg:grid-cols-3': cardLayout === 'multiple' && campaigns.length >= 3,
        })}
      >
        {/* TODO: refactor for more generic white-label card */}
        {campaigns.map((campaign) => {
          if (campaign.slug === 'david-2') {
            return (
              <DavidCampaignCard
                key={campaign.slug}
                campaign={campaign}
                layout={
                  campaigns.length === 1 || cardLayout === 'single'
                    ? 'horizontal'
                    : 'vertical'
                }
              />
            )
          }

          return (
            <CampaignCard
              key={campaign.slug}
              campaign={campaign}
              layout={
                campaigns.length === 1 || cardLayout === 'single'
                  ? 'horizontal'
                  : 'vertical'
              }
              noDescription
            />
          )
        })}
      </div>
    </div>
  )
}

const ABCampaignSection = (props: any) => {
  return (
    <ABTester name="single_card_layout">
      {({ decision }: ABTestProps) => (
        <CampaignSection
          {...props}
          cardLayout={decision.variationKey === 'on' ? 'single' : 'multiple'}
        />
      )}
    </ABTester>
  )
}

export default ABCampaignSection

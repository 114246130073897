import useApi, { ApiRes } from '@/services/hooks/useApiV2'
import { getAllCampaigns, Campaign } from '@/services/api/campaignV2/campaign'

const useCampaigns = () => {
  const {
    data: campaigns,
    isLoading,
    isError,
  }: ApiRes<Campaign[]> = useApi({
    key: `campaigns`,
    request: getAllCampaigns,
  })

  return {
    campaigns,
    isLoading,
    isError,
  }
}

export default useCampaigns
